import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import colors from "./colors"
import Button from "./button"
import DropdownAccessible from "./dropdownAccessible"

import GCSpin from "../images/gcspin.gif"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const TopBox = styled.nav`
  height: 15.5em;
  position: sticky;
  z-index: 0;
  ${mq[1]} {
    height: 14em;
  }
  ${mq[2]} {
    height: 17em;
  }
  &:hover,&:focus{
    z-index: 2;
  }
`

const TopBar = css`
    background: ${colors.lightpink};
    height: 15em;
    position: sticky;
    z-index: 0;
    ${mq[1]} {
      height: 13.5em;
    }
    ${mq[2]} {
      height: 4.6em;
      background: ${colors.pink};
      border-bottom: 3px solid ${colors.lightpink};
    }
`

const BarGrid = styled.div`
  position: sticky;
  z-index: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  align-items: start;
  ${mq[2]} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

const ButtonsResponsive = styled.div`
  position: sticky;
  z-index: 2;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  grid-column: 1;
  grid-row: 2;
  align-items: center;
  width: auto;
  padding: 1px;
  ${mq[2]} {
    grid-column: 1;
    grid-row: 1;
    margin: 1rem;
    align-items: center;
  }
`

const Logo = styled.img`
  position: sticky;
  display: grid;
  height: 150px;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  grid-row: 1;
  grid-column: 1;
  ${mq[2]} {
    display: block;
    position: absolute;
    height: 240px;
    margin: 1rem auto;
    top: 0;
    left: 0;
    right: 0;
    grid-row: 1;
  }
`

const Bar = () => {

  //create parent callback to query if dropdown is open for each dropdown below
  const [expandStateOne, setExpandStateOne] = useState(false)

  const onExpandOne = (checkExpandOne) => {
    setExpandStateOne(checkExpandOne)
  }

  useEffect(() => {
    onExpandOne(expandStateOne)
  })

  const [expandStateTwo, setExpandStateTwo] = useState(false)

  const onExpandTwo = (checkExpandTwo) => {
    setExpandStateTwo(checkExpandTwo)
  }

  useEffect(() => {
    onExpandTwo(expandStateTwo)
  })

  //use boolean from callback to conditionally set z-index of dropdown menu
  const [zIndexValue, setZIndexValue] = useState('0')

  const zIndexUpdate = ( expandStateOne, expandStateTwo ) => {
      (expandStateOne === false && expandStateTwo === false) ? setZIndexValue('0') : setZIndexValue('2')
      return zIndexValue
  }

  useEffect(() => {
    zIndexUpdate(expandStateOne, expandStateTwo)
    console.log(zIndexValue)
  })

  return(
    <TopBox css={css`
      z-index: ${zIndexValue};
    `}>
      <div css={TopBar}>
        <BarGrid>
          <ButtonsResponsive>
            <DropdownAccessible
              menuTitle="live"
              menuItems={[
                {id: 0, name: 'livestream', link: '/live'},
                {id: 1, name: 'livestream schedule', link: '/live-schedule'},
                {id: 2, name: 'Chicago calendar', link: '/chicago-calendar'},
                {id: 3, name: 'add show to Chicago Calendar', link: '/add-a-show-to-the-calendar-chicago/'},
              ]}
              isExpanded={onExpandOne}
            />
            <DropdownAccessible
              menuTitle="reading"
              menuItems={[
                {id: 0, name: 'resources', link: '/tags/resources'},
                {id: 1, name: 'blog', link: '/blog'},
                {id: 2, name: 'all tags', link: '/tags'},
              ]}
              isExpanded={onExpandTwo}
            />
            <Button
              text="label"
              link="/tags/groove-cafe-label/"
            />
            <Button
              text="info"
              link="/about"
            />
          </ButtonsResponsive>
          <Link to="/">
            <Logo
              src={GCSpin}
              alt="Groove Café logo"
              title="home"
            />
          </Link>
        </BarGrid>
      </div>
    </TopBox>
      )
}

export default Bar
