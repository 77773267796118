export default {
  yellow: "#fec75e",
  pink: "#f6c5cb",
  navy: "#16237e",
  lightnavy: "#2439D6",
  lightyellow: "#faf0dc",
  lightpink: "#fae3e6",
  darkpink: "#f17c99",
  green: "#50CF0C",
  slate: "#2b2b2b",
}
