import React from "react"
import { Link } from "gatsby"

import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { faBandcamp, faInstagram, faTwitter, faSoundcloud } from '@fortawesome/free-brands-svg-icons'

import "../fonts/fonts.css"
import colors from "./colors"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Bar = styled.footer`
  display: flex;
  flex-flow: row;
  background-color: ${colors.lightyellow};
  width: 100%;
  height: 3rem;
  margin-top: 2rem;
  align-items: center;
  ${mq[1]} {
    border-top: 3px solid ${colors.darkpink};
  }
`

const Text = styled.p`
  margin: 0 1rem;
  font-family: Aleo;
`

const Social = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  margin-left: auto;
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
`

const SocialLink = styled.a`
  padding: 0;
  color: ${colors.slate}
`

const LinkText = css`
  text-decoration: none;
  color: ${colors.slate}
`

const Footer = () => {

  return(
    <Bar>
      <Link to="/about" css={LinkText}>
        <Text>
          about
        </Text>
      </Link>
      <Link to='/email-signup' css={LinkText}>
        <Text>
          mailing list
        </Text>
      </Link>
      <Social>
        <SocialLink href="https://soundcloud.com/groove_cafe/" aria-label="listen to our Weekly Specials mix series on Soundcloud">
          <FontAwesomeIcon icon={faSoundcloud} />
        </SocialLink>
        <SocialLink href="https://groovecafe.bandcamp.com" aria-label="listen to releases on Groove Café's Bandcamp">
          <FontAwesomeIcon icon={faBandcamp} />
        </SocialLink>
        <SocialLink href="https://instagram.com/_groove_cafe" aria-label="follow Groove Café on Instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </SocialLink>
        <SocialLink href="https://twitter.com/_groove_cafe" aria-label="follow Groove Café on Instagram">
          <FontAwesomeIcon icon={faTwitter} />
        </SocialLink>
        <SocialLink href="mailto:host@groove.cafe" aria-label="email Groove Café">
          <FontAwesomeIcon icon={faEnvelopeOpenText} />
        </SocialLink>
      </Social>
    </Bar>
  )
}

export default Footer
