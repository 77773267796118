import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'

import colors from "./colors"

const Wrapper = styled.div`
  position: sticky;
  z-index: 2;
  padding: 5px;
`

const ButtonStyle = css`
  position: sticky;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.navy};
  border-radius: 5px;
  background-color: ${colors.lightpink};
  box-shadow: -3px 3px ${colors.navy};
  color: ${colors.navy};
  text-align: center;
  width: fit-content;
  height: fit-content;
  padding: 1px 6px;
  font-family: Aleo;
  font-size: 16px;
  &:hover,&:focus {
    border-color: white;
    background-color: ${colors.darkpink};
    color: white;
    cursor: pointer;
  }
`

const Menu = styled.ul`
  position: absolute;
  display: flex;
  flex-flow: column;
  list-style-type: none;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.navy};
  border-radius: 5px;
  background-color: ${colors.lightpink};
  box-shadow: -3px 3px ${colors.navy};
  color: ${colors.navy};
  margin-top: 20px;
  width: 150px;
  height: auto;
  margin: 10px 0px;
`

const Items = styled.li`
  width: auto;
  height: auto;
  margin: 2px 0px;
  padding: 2px 10px;
  font-size: 85%;
  font-family: Aleo;
  color: ${colors.navy};
  line-height: 1.5em;
  &:hover,&:focus {
    background-color: rgb(256,256,256, 0.7);
    color: ${colors.darkpink}
  }
`

const Text = css`
  text-decoration: none;
`

const Dropdown = ({ menuTitle, menuItems, isExpanded }) => {

  const node = useRef()

  const [open, setOpen] = useState(false)

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  //make menu fit number of items inherited from parent
  const numberOfItems = Object.keys(menuItems).length

  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(numberOfItems)

  //callback to pass isOpen boolean to parent

  useEffect(() => {
    isExpanded(isOpen)
  })

  return(
    <Wrapper
      ref={node}
      {...isExpanded}
    >
      <button
        {...buttonProps}
        css={ButtonStyle}
      >
        {menuTitle}
      </button>
      {isOpen && (
        <Menu
          role='menu'
        >
          {menuItems.map((item) => (
            <Link to={item.link} css={Text}>
              <Items {...itemProps[item.id]}>
                ~~ {item.name}
              </Items>
            </Link>
          ))}
        </Menu>
      )}
    </Wrapper>
  )
}

export default Dropdown
