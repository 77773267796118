/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled"

import Header from "./header"
import "./global.css"
import Footer from "./footer"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  position: sticky;
  z-index: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Main = styled.main`
  position: sticky;
  z-index: 0;
  display: flex;
  flex-flow: column;
  min-height: 100%;
  flex: 1;
  padding: 0 1rem;
`

const Body = styled.div`
  position: sticky;
  z-index: 0;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  ${mq[2]} {
    display: grid;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <Wrapper>
        <Header />
        <Main>
          <Body>
            <main>{children}</main>
          </Body>
        </Main>
        <Footer />
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
