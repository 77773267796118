import React from "react"
import PropTypes from "prop-types"

import Navbar from "./navbar"

const Header = ({ siteTitle, image }) => (
  <header>
    <div>
      {siteTitle}
    </div>
    <Navbar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
