import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import colors from "./colors"

const ButtonWrapper = styled.div`
  background: transparent;
  border-style: none;
  padding: 5px;
  text-decoration: none;
`

const Box = css`
    border-style: solid;
    border-width: 1px;
    border-color: #16237e;
    border-radius: 5px;
    background-color: ${colors.lightpink};
    box-shadow: -3px 3px ${colors.navy};
    color: ${colors.navy};
    text-decoration: none;
    text-align: center;
    width: fit-content;
    height: fit-content;
    padding-left: 5px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-family: Aleo;
    font-size: 16px;
    &:hover,&:focus {
      border-color: white;
      background-color: ${colors.darkpink};
      color: white;
      cursor: pointer;
    }
`

const Text = css`
  text-decoration: none;
`

const Button = (props) => (
    <ButtonWrapper>
      <a href={props.href} css={Text}>
        <Link to={props.link} css={Text}>
          <button css={Box}>
            {props.text}
          </button>
          </Link>
      </a>
    </ButtonWrapper>
  )

export default Button
